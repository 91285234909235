import React from 'react'
import { createPortal } from 'react-dom'
import { fontBold, preset, sizeH6 } from 'src/lib/typography'
import styled from 'styled-components'

export const Container = styled.aside`
  margin: 32px 0 48px;
  z-index: 1000;

  @media (min-width: 800px) {
    grid-column: 2 / 5 !important;
    grid-row: 1 / 2;
    align-self: start;

    margin: 80px 0 150px;

    position: sticky;
    top: calc(90px + 30px);
    left: 0;
  }
`

const Heading = styled.h4`
  color: var(--squash800);
  ${fontBold}
  ${sizeH6}
  margin: 0 0 24px;
  text-transform: uppercase;
`

const Body = styled.div`
  border-left: 4px solid var(--squash600);
  /* position: relative; */
`

const List = styled.ul`
  margin: 0;
  padding: 0 0 0 16px;
  list-style: none;
`

const Detail = styled.li`
  color: var(--black);
  ${preset}
  margin: 0 0 24px;

  &:last-child {
    margin: 0;
  }
`

export default function Client({ data }) {
  return (
    <Container>
      <Heading>Client Overview</Heading>
      <Body>
        <List>
          {data?.details.map((detail, index) => (
            <Detail key={index} $preset="h6">
              {detail.text}
            </Detail>
          ))}
        </List>
      </Body>
    </Container>
  )
}
