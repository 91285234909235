import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { Container, ColumnGrid } from 'src/components/Layout'
import { preset } from 'src/lib/typography'
import { ButtonStyledAsButton, LinkStyledAsButton } from '../Elements'

const CoverContainer = styled(Container)`
  padding-top: 95px;
  padding-right: 0px;
  padding-bottom: 30px;
  padding-left: 0px;

  @media (min-width: 800px) {
    padding-top: 215px;
    padding-right: 0px;
    padding-bottom: 60px;
    padding-left: 0px;
  }
`

const Content = styled.div`
  grid-column: 1 / 7;
  @media (min-width: 800px) {
    grid-column: 2 / 12;
  }
`

const Eyebrow = styled.span`
  display: block;
  ${preset}
  color: var(--squash300);
  margin: 0 0 16px;
`

const Headline = styled.h1`
  ${preset}
  color: var(--white);
  margin: 0 0 32px;
`

const Link = styled.span`
  ${preset}
  display: block;
  color: var(--white);
`

export default function Cover({ data }) {
  return (
    <CoverContainer background={data?.background} top={true} zIndex={1}>
      <ColumnGrid>
        <Content>
          <Eyebrow $preset="h5">Case Study</Eyebrow>
          <Headline $preset="h4">{data?.headline}</Headline>
          {data?.pdf?.localFile && (
            <ButtonStyledAsButton
              onClick={() =>
                typeof window !== 'undefined' &&
                window.open(data?.pdf?.localFile?.publicURL, '_blank')
              }
            >
              Download (PDF)
            </ButtonStyledAsButton>
          )}
        </Content>
      </ColumnGrid>
    </CoverContainer>
  )
}

export const fragment = graphql`
  fragment CaseStudyCoverFields on WpCaseStudy {
    caseStudyCover {
      background {
        kind
        colour {
          desktop
          mobile
        }
        hasFilter
        image {
          desktop {
            ...DesktopImageFields
          }
          mobile {
            ...MobileImageFields
          }
        }
        divider
      }
      headline
      client {
        details {
          text
        }
      }
      pdf {
        localFile {
          publicURL
        }
      }
    }
  }
`
