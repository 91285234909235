import React, { useRef, useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { Head, ColumnGrid } from 'src/components/Layout'
import { getLayouts } from 'src/lib/layout/getLayouts'
import { Cover, Client } from 'src/components/CaseStudy'
import { Container as ClientContainer } from 'src/components/CaseStudy/Client'
import { Related } from 'src/components/Post'

const Layouts = styled.div`
  position: relative;

  ${ClientContainer} {
    grid-row: 1 / 2;
    grid-column: 1 / 7;
  }

  @media (min-width: 800px) {
    ${ClientContainer} {
      grid-row: 1 / 2;
      grid-column: 2 / 5 !important;
    }

    ${ColumnGrid} > * {
      grid-column: 6 / 12;
    }
  }
`

interface CaseStudyProps {
  errors?: any
  data?: GatsbyTypes.WpCaseStudyQuery
}

export default function CaseStudy({
  data,
  errors,
}: CaseStudyProps): JSX.Element {
  errors && console.error(process.env.NODE_ENV === 'development' && errors)

  return (
    <>
      <Head
        meta={data?.wpCaseStudy?.ACF?.meta}
        page={{
          __typename: 'WpCaseStudy',
          title: data?.wpCaseStudy?.title || '',
          uri: data?.wpCaseStudy?.uri || '',
        }}
      />
      <Cover data={data?.wpCaseStudy?.caseStudyCover} />
      <Layouts>
        <div
          css={`
            background-color: var(--beige);

            @media (min-width: 800px) {
              background-color: unset;
            }
          `}
        >
          <ColumnGrid
            css={`
              @media (min-width: 800px) {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
              }
            `}
          >
            <Client data={data?.wpCaseStudy?.caseStudyCover?.client} />
          </ColumnGrid>
        </div>
        {getLayouts(data?.wpCaseStudy?.ACF?.layouts)}
      </Layouts>
      <Related
        title="Related case studies"
        posts={data?.relatedCaseStudies?.nodes}
      />
    </>
  )
}

export const caseStudyQuery = graphql`
  query WpCaseStudy($id: String!) {
    wpCaseStudy(id: { eq: $id }) {
      __typename
      id
      title
      uri
      date
      ...CaseStudyCoverFields
      ACF {
        ...MetaFields
        ...LayoutFields
        ...OptionsFields
      }
    }
    relatedCaseStudies: allWpCaseStudy(
      filter: { id: { ne: $id } }
      limit: 3
      sort: { order: DESC, fields: date }
    ) {
      nodes {
        ...CaseStudySummaryFields
      }
    }
  }
`
